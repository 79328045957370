.white {
    background-color: #ffffff;
}

.lightgray {
    background-color: #aaaaaa;
}

.darkgray {
    background-color: #5f5f5f;
}

.black {
    background-color: black;
}

.brown {
    background-color: rgb(126, 61, 0);
}

.red {
    background-color: rgb(216, 0, 0);
}

.orange {
    background-color: rgb(255, 123, 0);
}

.darkyellow {
    background-color: rgb(255, 186, 39);
}

.yellow {
    background-color: rgb(255, 255, 0);
}

.lightgreen {
    background-color: rgb(72, 255, 72);
}

.green {
    background-color: rgb(7, 194, 7);
}

.lightblue {
    background-color: rgb(0, 215, 223);
}

.blue {
    background-color: rgb(12, 141, 228);
}

.darkblue {
    background-color: rgb(13, 78, 175);
}

.purple {
    background-color: rgb(154, 83, 255);
}

.pink {
    background-color: rgb(255, 146, 199);
}

.colorpicker > div {
    width: 40px!important;
    height: 40px!important;
    border: 2px solid lightgray;
}

.pixel {
    width: 20px;
    height: 20px;
    border: 1px solid lightgray;
    box-sizing: border-box;
}

.pixel.current-color {
    border: 4px dashed rgb(143, 143, 143);
}

.selected {
    background-color: rgba(95, 204, 255, 0.95);
}