@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    background: #727272;
}

::-webkit-scrollbar-thumb {
    background: #c9c9c9;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #e9e9e9;
}

#connectbutton {
    background-image: linear-gradient(60deg,
    rgb(255, 96, 96) 17%,
    rgb(255, 181, 70) 27%,
    rgb(217, 233, 0) 36%,
    rgb(98, 214, 94) 44%,
    rgb(126, 206, 204) 52%,
    rgb(107, 203, 218) 60%,
    rgb(100, 162, 233) 68%,
    rgb(132, 79, 238) 76%,
    rgb(199, 68, 247) 84%,
    rgb(252, 53, 225) 92%,
    rgb(255, 67, 67) 100%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media (prefers-reduced-motion: no-preference) {
    #spinningLogo {
        animation: App-logo-spin infinite 10s linear;
    }
}

@keyframes loadingbar {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}

@media (prefers-reduced-motion: no-preference) {
    #loadingbar {
        animation: loadingbar 6s linear;
        width: 100%;
    }
}

.bgimage {
    background-image: url("./assets/images/bg.png");
}

#allpixels {
    box-shadow: 0px 0px 0px 2px rgb(90, 90, 90);
}